// ========================= carousels =========================
import Swiper from 'swiper';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import EmblaCarousel from 'embla-carousel'


document.addEventListener("DOMContentLoaded", function() {

   // ================= swiper hero =================
   const swiper = new Swiper('.swiperHero', {
      // Optional parameters
      modules: [Autoplay, Pagination],
      direction: 'horizontal',
      // loop: true,
      speed: 500,
      slidesPerView: "auto",
      centeredSlides: true,
      pagination: {
         el: ".swiper-pagination",
         clickable: true,
     },
   });

   const smdev_btn_prev = document.querySelector(".swiper-button-prev");
   const smdev_btn_next = document.querySelector(".swiper-button-next");

   if(smdev_btn_prev && smdev_btn_next){
      function smdev_handle_slider(e){
         let clickPrev = e.currentTarget.className.includes("swiper-button-prev");
         let clickNext = e.currentTarget.className.includes("swiper-button-next");
      
         if(clickPrev){
            swiper.slidePrev();
         }else if(clickNext){
            swiper.slideNext();
         }
      }
      
      smdev_btn_prev.addEventListener("click", smdev_handle_slider);
      smdev_btn_next.addEventListener("click", smdev_handle_slider);
   }

   // ================= swiper cards =================
   new Swiper(".swiperCards", {
      modules: [Pagination],
      slidesPerView: "auto",
      spaceBetween: 16,
      pagination: {
         el: ".swiper-pagination",
         clickable: true,
     },
   });
   
   
   // ================= embla carousel =================
   const emblaNodes = document.querySelectorAll('.embla'); // Select all slider containers

   emblaNodes.forEach((emblaNode) => {
   const options = { loop: false };
   const emblaApi = EmblaCarousel(emblaNode, options);

   // Find the specific dots container for this slider
   const dotsContainer = emblaNode.parentNode.querySelector('.embla__dots');
   const slidesCount = emblaApi.slideNodes().length;

   // Create dots based on the number of slides and append them to the existing dots container
   const dots = Array.from({ length: slidesCount }, (_, index) => {
      const dot = document.createElement('button');
      dot.classList.add('embla__dot');
      dotsContainer.appendChild(dot);
      dot.addEventListener('click', () => emblaApi.scrollTo(index)); // Click event for each dot
      return dot;
   });

   // Function to update the active dot
   const setActiveDot = () => {
      const selectedIndex = emblaApi.selectedScrollSnap();
      dots.forEach((dot, index) => {
         dot.classList.toggle('is-active', index === selectedIndex); // Update active dot
      });
   };

   // Listen for the 'select' event to update the active dot when the slide changes
   emblaApi.on('select', setActiveDot);

   // Set the initial active dot
   setActiveDot();
   });


})
// ========================= /carousels =========================



// ========================= countdown =========================
document.addEventListener('DOMContentLoaded', function() {
   if(document.getElementById('f1sp_days')){
      function updateCountdown() {
          var now = Math.floor(Date.now() / 1000);
          var f1sp_diff = f1sp_eventTimestamp - now;
   
          if (f1sp_diff <= 0) {
              document.getElementById('faltam').style.display = 'none';
              document.getElementById('f1sp_countdown').innerHTML = '<h1 style="font-weight: bold; color: white; font-size: 24px; color: #ffb600;">F1 São Paulo</h1>';
              return;
          }
   
          var days = Math.floor(f1sp_diff / (24 * 3600));
          f1sp_diff -= days * 24 * 3600;
   
          var hours = Math.floor(f1sp_diff / 3600);
          f1sp_diff -= hours * 3600;
   
          var minutes = Math.floor(f1sp_diff / 60);
          f1sp_diff -= minutes * 60;
   
          var seconds = f1sp_diff;
   
          document.getElementById('f1sp_days').textContent = padZero(days);
          document.getElementById('f1sp_hours').textContent = padZero(hours);
          document.getElementById('f1sp_minutes').textContent = padZero(minutes);
          document.getElementById('f1sp_seconds').textContent = padZero(seconds);
   
          setTimeout(updateCountdown, 1000);
      }
   
      function padZero(num) {
          return num.toString().padStart(2, '0');
      }
   
      updateCountdown();
   }
});
// ========================= /countdown =========================



// ========================= Header menu mobile =========================
document.addEventListener("DOMContentLoaded", function() {
   /* START ============= MENU MOBILE */
   const smdev_header_nav = document.querySelector('.smdev_header nav');
   const smdev_header_btn_burger = document.querySelector(".smdev_header_btn_burger");
   const smdev_header_btn_burger_div = document.querySelector(".smdev_header_btn_burger div");

   // Handle menu toggle

   function smdev_handle_menu_mobile(event) {
      if (event.cancelable) {
         event.preventDefault();
     }
      event.stopPropagation(); // Stop event propagation
      smdev_header_nav.classList.toggle("smdev_header_show");
      smdev_header_btn_burger_div.classList.toggle("smdev_close_icon");
      const isMenuOpen = smdev_header_nav.classList.contains("smdev_header_show");

      document.body.style.overflow = isMenuOpen ? "hidden" : "visible";
   }

   // Close menu if open and clicked outside of nav or sub-menu handling
   function closeMenuIfOpen(event) {
      const clickedElement = event.target;
      const hasSubMenu = clickedElement.nextElementSibling && clickedElement.nextElementSibling.tagName === "UL";
		
		if(hasSubMenu){
			clickedElement.nextElementSibling.classList.toggle("!flex");
         event.preventDefault();
		}

      if (!hasSubMenu && !smdev_header_nav.contains(clickedElement)) {
         if (smdev_header_nav.classList.contains("smdev_header_show")) {
            smdev_header_nav.classList.remove("smdev_header_show");
            smdev_header_btn_burger_div.classList.remove("smdev_close_icon");
            document.body.style.overflow = "visible";
         }
      }
   }

   // Listen for clicks and touch events
   smdev_header_btn_burger.addEventListener("click", smdev_handle_menu_mobile);
   smdev_header_btn_burger.addEventListener("touchstart", smdev_handle_menu_mobile);
   document.addEventListener("click", closeMenuIfOpen);
   /* END ============= MENU MOBILE */
});
// ========================= /Header menu mobile =========================



// ========================= Keep page position after switch language =========================
document.addEventListener('DOMContentLoaded', function() {
   if (localStorage.getItem('scrollPos') !== null) {
      window.scrollTo(0, localStorage.getItem('scrollPos'));
      localStorage.removeItem('scrollPos');
   }
   document.querySelectorAll('.lang-item').forEach(function(element) {
      element.addEventListener('click', function() {
         localStorage.setItem('scrollPos', window.scrollY);
      });
   });
});
// ========================= /end Keep page position after switch language =========================



// ==================== ACCORDION LIST ==================== //
document.addEventListener("DOMContentLoaded", () => {

   document.querySelectorAll('.accordionTrigger').forEach(button => {
       button.addEventListener('click', () => {
 
           const content = button.querySelector('.accordionContent');
           const iconPlus = button.querySelector(".iconPlus svg path:nth-child(2)");
 
           // Check if the accordion is open
           if (content.classList.contains("open")) {
 
               content.style.maxHeight = null;
               content.classList.remove("open");
               if (iconPlus) {
                   iconPlus.style.display = 'block';

               }
 
           } else {
 
               content.style.maxHeight = content.scrollHeight + 'px';
               content.classList.add("open");
               if (iconPlus) {
                   iconPlus.style.display = 'none';
               }
           }
       });
   });
 
});
// ==================== / ACCORDION LIST ==================== //



// ==================== Reels Pop Up Modal ==================== //
document.addEventListener("DOMContentLoaded", () => {
   if(document.getElementById('f1sp_popup')){
      const f1sp_openPopupButton = document.querySelectorAll('#open-popup');
      const f1sp_popup = document.getElementById('f1sp_popup');
      const f1sp_popup_iframe = f1sp_popup.querySelector('div iframe');
      const f1sp_closePopupButton = document.getElementById('close-popup');
   
      if(f1sp_popup){
   
         let f1sp_popup_timer;
      
         f1sp_openPopupButton.forEach((reel) => {
            reel.addEventListener('click', (event) => {
               event.preventDefault();
        
               if (f1sp_popup_timer) clearTimeout(f1sp_popup_timer); // Clear the previous timer
        
               f1sp_popup_timer = setTimeout(() => {
                   const ytVideoCode = reel.getAttribute('href');
                   if (ytVideoCode) {
                       f1sp_popup_iframe.setAttribute("src", ytVideoCode);
                       f1sp_popup.classList.add('f1sp_popup_active');
                   }
               }, 500);
           });
         })
      
         f1sp_closePopupButton.addEventListener('click', () => {
             f1sp_popup.classList.toggle('f1sp_popup_active');
             f1sp_popup_iframe.setAttribute("src", "");
         });
      
         document.addEventListener('click', (event) => {
             if (event.target === f1sp_popup) {
                 f1sp_popup.classList.toggle('f1sp_popup_active');
                 f1sp_popup_iframe.setAttribute("src", "");
             }
         });
      }
   }
})
 // ==================== /Reels Pop Up Modal ==================== //



// ==================== /page_programacao_accordion ==================== //
document.addEventListener('DOMContentLoaded', function () {
   const buttons = document.querySelectorAll('.triggerProgramacao');
   const sections = document.querySelectorAll('.programacao');
   const fanzoneButtons = document.querySelectorAll('.triggerFanzoneProgramacao');
   const fanzoneSections = document.querySelectorAll('.fanzoneProgramacao');

   // Event listener for original buttons
   buttons.forEach(button => {
      button.addEventListener('click', function () {

         const targetId = `${this.id}_info`;
         buttons.forEach(btn => btn.classList.remove("highlight_button_event"));
         this.classList.add("highlight_button_event");
         
         sections.forEach(section => {
            if (section.id === targetId) {
               section.classList.remove('hidden');
            } else {
               section.classList.add('hidden');
            }
         });
      });
   });

   // Event listener for fanzone buttons
   fanzoneButtons.forEach(button => {
      button.addEventListener('click', function () {

         const targetId = `${this.id}_info`;
         fanzoneButtons.forEach(btn => btn.classList.remove("highlight_button_event"));
         this.classList.add("highlight_button_event");

         fanzoneSections.forEach(section => {
            if (section.id === targetId) {
               section.classList.remove('hidden');
            } else {
               section.classList.add('hidden');
            }
         });
      });
   });
});
 // ==================== /page_programacao_accordion ==================== //
 
 
// ==================== page_interlagos_carousel_circuit ==================== //
// Initialize the Swiper for images
const imageSwiper = new Swiper('.imageSwiper', {
   slidesPerView: 1,
   centeredSlides: true,
   spaceBetween: 16,
   effect: 'fade',
   allowTouchMove: false,
});

// Initialize the Swiper for content
const contentSwiper = new Swiper('.contentSwiper', {
   modules: [Navigation],
   slidesPerView: 1,
   centeredSlides: true,
   navigation: {
       nextEl: '.circuito_button_next',
       prevEl: '.circuito_button_prev',
   },
   on: {
       slideChange: function () {
           imageSwiper.slideTo(this.activeIndex); // Sync the image swiper
       }
   }
});
// ==================== /page_interlagos_carousel_circuit ==================== //


// ==================== /page_interlagos_mapa_de_setores ==================== //
document.addEventListener('DOMContentLoaded', function () {
   const buttons_setor = document.querySelectorAll('.triggerMapaSetor');
   const sections_setor = document.querySelectorAll('.sectionMapaSetor');

   // Event listener for original buttons
   buttons_setor.forEach(button => {
      button.addEventListener('click', function () {

         const targetId = `${this.id}_section`;
         buttons_setor.forEach(btn => btn.classList.remove("highlight_button_event"));
         this.classList.add("highlight_button_event");
         
         sections_setor.forEach(section => {
            if (section.id === targetId) {
               section.classList.remove('hidden');
            } else {
               section.classList.add('hidden');
            }
         });
      });
   });
});
// ==================== /page_interlagos_mapa_de_setores ==================== //



// ==================== page_noticias ==================== //
document.addEventListener('DOMContentLoaded', function () {
   const buttons = document.querySelectorAll('.triggerNoticias');
   const sections = document.querySelectorAll('.sectionNoticias');

   // Event listener for original buttons
   buttons.forEach(button => {
      button.addEventListener('click', function () {

         const targetId = `${this.id}_noticias`;
         buttons.forEach(btn => btn.classList.remove("highlight_button_event"));
         this.classList.add("highlight_button_event");
         
         sections.forEach(section => {
            if (section.id === targetId) {
               section.classList.remove('hidden');
            } else {
               section.classList.add('hidden');
            }
         });
      });
   });
  
});
 // ==================== /page_noticias ==================== //




 /* ================= cookie banner ==================== */
document.addEventListener("DOMContentLoaded", () => {
   const banner = document.getElementById("cookie-banner");
   const acceptButton = document.getElementById("accept-cookies");

   if (localStorage.getItem("cookieConsent") === null) {
       banner.style.display = "flex";
   }
   if (localStorage.getItem("cookieConsent") === "true") {
       banner.style.display = "none";
   }

   acceptButton.addEventListener("click", function() {
       localStorage.setItem("cookieConsent", "true");
       banner.style.display = "none";
   });
})
/* ================= /cookie banner ==================== */